var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Header Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.headerColor),expression:"ic.headerColor"}],staticClass:"input",attrs:{"type":"color","name":"Header Color"},domProps:{"value":(_vm.ic.headerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "headerColor", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Footer Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.footerColor),expression:"ic.footerColor"}],staticClass:"input",attrs:{"type":"color","name":"Footer Color"},domProps:{"value":(_vm.ic.footerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "footerColor", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Secondary Banner Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.secondaryBannerColor),expression:"ic.secondaryBannerColor"}],staticClass:"input",attrs:{"type":"color","name":"Secondary Banner Color"},domProps:{"value":(_vm.ic.secondaryBannerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "secondaryBannerColor", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('p',{staticClass:"content"},[_c('em',[_vm._v("* indicates required field")])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }