<template>
  <validation-observer v-slot="{ invalid }" class="container">
    <div class="columns">
      <div class="column is-one-fifth">
        <validation-provider
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
          rules="required"
        >
          <div class="control">
            <label class="label">Header Color</label>
            <input
              type="color"
              class="input"
              v-model="ic.headerColor"
              name="Header Color"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </div>
        </validation-provider>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-fifth">
        <validation-provider
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
          rules="required"
        >
          <div class="control">
            <label class="label">Footer Color</label>
            <input
              type="color"
              class="input"
              v-model="ic.footerColor"
              name="Footer Color"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </div>
        </validation-provider>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-fifth">
        <validation-provider
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
          rules="required"
        >
          <div class="control">
            <label class="label">Secondary Banner Color</label>
            <input
              type="color"
              class="input"
              v-model="ic.secondaryBannerColor"
              name="Secondary Banner Color"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </div>
        </validation-provider>
      </div>
    </div>
    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      ic: {
        instanceId: null,
        secondaryBannerColor: "#0071bc",
        headerColor: "#0071bc",
        footerColor: "#0071bc"
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        await vm.fetchPaymentPortalInstanceConfig(to.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm, to.params.id);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceMain; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView(to.params.id);
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    loadView(id) {
      this.ic.instanceId = id;
      this.ic.secondaryBannerColor = this.$store.state.instanceConfig.secondaryBannerColor;
      this.ic.headerColor = this.$store.state.instanceConfig.headerColor;
      this.ic.footerColor = this.$store.state.instanceConfig.footerColor;
    },
    ...mapActions("inst", ["fetchInstances"]),
    ...mapActions([
      "fetchPaymentPortalGlobalConfig",
      "fetchPaymentPortalInstanceConfig",
      "saveInstanceConfig"
    ]),
    async save() {
      await this.saveInstanceConfig(this.ic);
      window.scrollTo(0, 0);
    },
    async revert() {
      this.loadView();
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Instance config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="scss">
.clob {
  white-space: pre-wrap;
}
</style>
